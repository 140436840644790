<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { EngineStore } from '~/stores/engine'
  import { PlanType } from '~/generated/api-clients-generated'

  const props = defineProps<{
    quote: QuotedPlanVm
    id: string
    hideHeader?: boolean
  }>()

  const engine = EngineStore.use()
  const emptyState = computed(() => engine.isEmptyState)
  const { flag } = FeatureFlags.use()
  const { maybeDo } = ActionGuard.use()

  const costEssentialsSidebar = computed(
    () => flag<boolean>('quote-card-cost-essentials-sidebar').value
  )

  const estYearlyDrugCosts = computed(() => {
    if (props.quote.details.year === 2025 && props.quote.drugCoverage.allCovered) {
      return _clamp(props.quote.drugCost, 2000)
    }
    return props.quote.drugCost
  })

  const drugCosts = computed(() =>
    _flatMap(props.quote.drugCoverage?.pharmacyCosts?.[0]?.monthlyCosts, (x) => x.details).reduce(
      (acc, curr) => ({
        ...acc,
        [curr!.externalId!]: (acc[curr!.externalId!] ?? 0) + (curr!.memberCost ?? 0)
      }),
      {} as Record<string, number>
    )
  )

  const isMapd = computed(() => props.quote.type === PlanType.MAPD)

  const { getId } = Id.use(props.id)
  const { show } = DialogController.use()
</script>

<template>
  <section class="flex w-full flex-col">
    <header class="content-section-header bg-gray-200" v-if="!hideHeader">
      <div>
        <h2>Plan Preview</h2>
      </div>
      <QuoteCardCostEssentialsSidebar
        :quote="quote"
        :empty-state="emptyState!"
        :id="getId(`cost-essentials-sidebar`)"
        v-if="isMapd && costEssentialsSidebar"
      />
    </header>
    <dl class="content-section-data">
      <div class="content-row">
        <dt>
          <PxTooltip name="PlanPremiums" text="Monthly Premium" />
        </dt>
        <dl>
          {{ $formatUSD(quote.details.premium) }}
        </dl>
      </div>
      <div class="content-row" v-if="quote.type === PlanType.MAPD">
        <dt>
          <PxTooltip name="MaxOutOfPocket" text="Max Out-of-Pocket" />
        </dt>
        <dl>
          {{ $formatUSD(quote.details.maxOutOfPocket) }}
        </dl>
      </div>
      <div class="content-row" v-if="quote.type === PlanType.PDP">
        <dt>
          <PxTooltip name="DrugDeductible" text="Drug Deductible" />
        </dt>
        <dl>
          {{ $formatUSD(quote.details.drugDeductible) }}
        </dl>
      </div>
      <div class="content-row">
        <PxCollapsible
          :id="getId('estimated-yearly-drug-costs')"
          class="preview-drugs"
          v-if="quote.drugCoverage.count > 0"
        >
          <template #header="{ open }">
            <dt :class="['collapsible-tooltip', { 'open-tooltip': open }]">
              <PxTooltip name="EstimatedDrugCosts" text="Estimated Yearly Drug Costs" />
            </dt>
            <dl>
              {{ $formatUSD(estYearlyDrugCosts) }}
            </dl>
          </template>
          <ul class="mb-2 mt-4 flex w-full flex-col gap-y-2 border-l border-border px-2">
            <li
              v-for="drug in quote.drugCoverage.coverageSummaries"
              :key="drug.ndc!"
              class="flex w-full flex-col items-start justify-between text-base xs:flex-row"
            >
              <div>
                {{ drug.drugName }}
              </div>
              <div class="w-1/3 text-nowrap text-right">
                {{ $formatUSD(drugCosts[drug.externalId!]) }} / year
              </div>
            </li>
          </ul>
          <HpAnchor
            class="flex indent-2 text-base font-semibold underline-offset-2"
            @click.prevent="() => maybeDo(() => show('update-drugs-pharmacy-sidebar'))"
          >
            <span>Edit Drugs</span>
          </HpAnchor>
        </PxCollapsible>
        <template v-else>
          <PxTooltip name="EstimatedDrugCosts" text="Estimated Yearly Drug Costs" />
          <HpAnchor
            class="flex items-center no-underline"
            @click.prevent="() => show('update-drugs-pharmacy-sidebar')"
          >
            <HpIcon name="circle-plus" />
            <span class="font-semibold text-primary">Add Drugs</span>
          </HpAnchor>
        </template>
      </div>
      <div class="content-row" v-if="quote.type === PlanType.MAPD">
        <PxCollapsible
          :id="getId('doctor-coverage')"
          class="preview-providers"
          v-if="quote.providerCoverage.count > 0"
        >
          <template #header="{ open }">
            <dt :class="['collapsible-tooltip', { 'open-tooltip': open }]">
              <PxTooltip name="DoctorsInNetwork" text="Doctors In-Network" />
            </dt>
            <dl>
              {{ quote.providerCoverage.inNetworkCount }} of
              {{ quote.providerCoverage.count }} Covered
            </dl>
          </template>
          <ul class="mb-2 mt-4 flex w-full flex-col gap-y-2 border-l border-border px-2">
            <li
              v-for="dr in quote.providerCoverage.providers"
              :key="dr.npi!"
              class="flex w-full items-start justify-between text-base"
            >
              <div>
                {{ dr.name }}
              </div>
              <div class="flex w-1/2 items-center justify-end gap-x-1 text-nowrap sm:w-1/3">
                <HpIcon v-if="dr.inNetwork" name="check" class="scale-75 text-success" />
                <HpIcon v-else class="text-danger-dark" name="no" />
                {{ dr.inNetwork ? 'In Network' : 'Out of Network' }}
              </div>
            </li>
          </ul>
          <div>
            <HpAnchor
              class="flex indent-2 text-base font-semibold underline-offset-2"
              @click.prevent="() => maybeDo(() => show('update-doctors-sidebar'))"
            >
              <span>Edit Doctors</span>
            </HpAnchor>
          </div>
        </PxCollapsible>
        <template v-else>
          <PxTooltip name="DoctorsInNetwork" text="Doctors In-Network" />
          <HpAnchor
            class="flex items-center no-underline"
            @click.prevent="() => show('update-doctors-sidebar')"
          >
            <HpIcon name="circle-plus" />
            <span class="font-semibold text-primary">Add Doctors</span>
          </HpAnchor>
        </template>
      </div>
    </dl>
    <div class="flex w-full justify-center">
      <QuoteCardCostsSidebar
        :id="getId(`cost-breakdown-sidebar`)"
        :quote="quote"
        :empty-state="emptyState!"
        version="CostsPreview"
      />
    </div>
  </section>
</template>

<style scoped>
  .content-section-data {
    @apply !pl-5 !pr-8;

    .content-row {
      dl {
        @apply !text-2xl;
      }
    }
  }

  .collapsible-tooltip.open-tooltip {
    :deep(.tooltip) {
      @apply font-bold;
    }
  }
</style>
